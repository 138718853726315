import { Api } from '@/core/api.js'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import localforage from 'localforage'
import Vue from 'vue'

const api = new Api()

const db_updates = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'updates' })
const db_templates = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'templates' })
const db_employees = localforage.createInstance({ name: 'ALEXCENTRAL', storeName: 'employee' })
const db_logbook = localforage.createInstance({ name: 'alexdb', storeName: 'logbook' })
const db_categories = localforage.createInstance({ name: 'alexdb', storeName: 'product_categories' })
const db_products = localforage.createInstance({ name: 'alexdb', storeName: 'products' })

// initial state
const getDefaultState = () => {
    return {
        hasLogin: false,
        local: {
            token: localStorage.token,
            location: localStorage.location ? JSON.parse(localStorage.location) : {},
            account: localStorage.account ? JSON.parse(localStorage.account) : false,
            user: localStorage.user ? JSON.parse(localStorage.user) : false,
            app_icon: typeof localStorage.app_icon !== 'undefined' ? localStorage.app_icon : 'alex_logo_default_01.png'
        },
        config: {},
        releases: {},
        allReleases: {},
        blackListData: {
            warning: false,
            blocked: false
        },
        trial_end_date: null,
        trial_link: null,
        productAvailability: 1,
        type: false,
        employeeMultiaccount: false,
        reportAccess: localStorage.getItem('reportAccess') ? JSON.parse(localStorage.getItem('reportAccess')) : false,
        customFields: [],
        external: 0
    }
}
const state = getDefaultState()

;(async () => {
    const employee = JSON.parse(localStorage.getItem('employeeMultiaccount'))
    state.employeeMultiaccount = employee
})()

// getters
const getters = {
    getHasLogin: (state, getters, rootState) => {
        return state.hasLogin
    },
    getLocalToken: (state, getters, rootState) => {
        return state.local.token
    },
    getLocalLocation: (state, getters, rootState) => {
        return state.local.location
    },
    getLocalAccount: (state, getters, rootState) => {
        return state.local.account
    },
    getLocalEmployee: (state, getters, rootState) => {
        if (typeof state.local.user !== 'undefined' && state.local.user != false) {
            return state.local.user
        } else {
            return false
        }
    },
    getCustomFields: (state, getters, rootState) => {
        return state.customFields
    },
    getConfig: (state, getters, rootState) => {
        return state.config
    },
    getAppIcon: (state, getters, rootState) => {
        return state.local.app_icon
    },
    getRoles: (state, getters, rootState) => {
        return typeof localStorage.roles !== 'undefined' ? JSON.parse(localStorage.roles) : {}
    },
    getReleases: (state, getters, rootState) => (id) => {
        if (id) {
            var item = state.releases[id]
            // item.created_date = moment(item.created_date * 1000)
            return item
        } else {
            var items = state.releases
            items = Object.values(items).sort((a, b) =>
                moment(a.created_date * 1000) < moment(b.created_date * 1000) ? 1 : moment(b.created_date * 1000) < moment(a.created_date * 1000) ? -1 : 0
            )
            return items
        }
    },
    getAllReleases: (state, getters, rootState) => (id) => {
        if (id) {
            var item = state.allReleases[id]
            // item.created_date = moment(item.created_date * 1000)
            return item
        } else {
            var items = state.allReleases
            items = Object.values(items).sort((a, b) =>
                moment(a.created_date * 1000) < moment(b.created_date * 1000) ? 1 : moment(b.created_date * 1000) < moment(a.created_date * 1000) ? -1 : 0
            )
            return items
        }
    },
    getBlackListData: (state, getters, rootState) => {
        return state.blackListData
    },
    getTrialEndDate: (state, getters, rootState) => {
        return state.trial_end_date
    },
    getTrialLink: (state, getters, rootState) => {
        return state.trial_link ? state.trial_link : i18n.t('trial.action_link')
    },
    getProductAvailability: (state, getters, rootState) => {
        return state.productAvailability
    },
    getType: (state, getters, rootState) => {
        return state.type ? state.type : 2
    },
    getEmployeeMultiAccount: (state, getters, rootState) => {
        if (state.employeeMultiaccount) {
            return state.employeeMultiaccount
        }
        const employee = JSON.parse(localStorage.getItem('employeeMultiaccount'))
        return employee
    },
    getReportAccess: (state, getters, rootState) => {
        return state.reportAccess
    },
    getFilterViewsAvailable: (state, getters, rootState) => {
        if (typeof state.local.user !== 'undefined' && state.local.user != false) {
            return Boolean(parseInt(state.local.user.config.filterViewsAvailable))
        } else {
            return false
        }
    },
    getReportEditionAvailable: (state, getters, rootState) => {
        if (typeof state.local.user !== 'undefined' && state.local.user != false) {
            return Boolean(parseInt(state.local.user.config.reportEditionAvailable))
        } else {
            return false
        }
    },
    getExternal: (state, getters, rootState) => {
        return state.external
    }
}

// actions
const actions = {
    changeAccount(context, params) {
        return api.post('admin/init/' + params.id, params.data).then(function (response) {
            if (response.status) {
                db_updates.clear()
                db_templates.clear()
                db_employees.clear()
                db_logbook.clear()
                db_categories.clear()
                db_products.clear()

                var roles = response.data.roles
                context.commit('employee/setRoles', roles, { root: true })
                delete response.data.roles

                var account = {
                    id: response.data.account_id,
                    avatar: response.data.avatar,
                    account_name: response.data.account_name,
                    timezone: response.data.timezone,
                    config: response.data.config
                }
                context.commit('account/setOrganization', { account: account }, { root: true })
                context.commit('setReleases', response.data)
                context.commit('login', response)
                context.commit('setChatActive', response.data.chat, { root: true })
                context.rootState.language = localStorage.getItem('language')

                const canCreateUsers =
                    response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' && response.data.config.canCreateUsers
                        ? response.data.config.canCreateUsers
                        : 0
                context.commit('setCanCreateUsers', canCreateUsers, { root: true })

                const productNextState =
                    response.data.config && typeof response.data.config.nextState !== 'undefined' && response.data.config.nextState
                        ? response.data.config.nextState
                        : 0
                context.commit('setProductNextState', productNextState, { root: true })

                return true
            } else {
                return false
            }
        })
    },
    doLogin(context, params) {
        return api
            .login('login/admin', { email: params.email, password: params.password })
            .then(function (response) {
                if (response.status) {
                    db_updates.clear()
                    db_templates.clear()
                    db_employees.clear()
                    db_logbook.clear()
                    db_categories.clear()
                    db_products.clear()

                    var roles = response.data.roles
                    context.commit('employee/setRoles', roles, { root: true })
                    delete response.data.roles
                    var account = {
                        id: response.data.account_id,
                        avatar: response.data.avatar,
                        account_name: response.data.account_name,
                        timezone: response.data.timezone
                    }
                    context.commit('account/setOrganization', { account: account }, { root: true })
                    context.commit('setReleases', response.data)
                    context.commit('login', response)
                    context.commit('setChatActive', response.data.chat, { root: true })
                    context.rootState.language = localStorage.getItem('language')
                } else {
                    if (response.blackListWarning) {
                        context.commit('setBlacklistData', { warning: true, blocked: false })
                    }
                    if (response.blackListBlocked) {
                        context.commit('setBlacklistData', { warning: false, blocked: true })
                    }
                    return false
                }
            })
            .then(function () {
                if (context.state.hasLogin) {
                    return true
                } else {
                    return false
                }
            })
    },
    validateExternalAccess(context, params) {
        return api
            .login('login/admin/external', { account_id: params.accountId, employee_id: params.id })
            .then(function (response) {
                if (response.status) {
                    db_updates.clear()
                    db_templates.clear()
                    db_employees.clear()
                    db_logbook.clear()
                    db_categories.clear()
                    db_products.clear()

                    var roles = response.data.roles
                    context.commit('employee/setRoles', roles, { root: true })
                    delete response.data.roles
                    var account = {
                        id: response.data.account_id,
                        avatar: response.data.avatar,
                        account_name: response.data.account_name,
                        timezone: response.data.timezone
                    }
                    context.commit('account/setOrganization', { account: account }, { root: true })
                    context.commit('setReleases', response.data)
                    context.commit('login', response)
                    context.commit('setChatActive', response.data.chat, { root: true })
                    context.commit('setExternal', parseInt(response.data.external))
                    context.rootState.language = localStorage.getItem('language')
                } else {
                    if (response.blackListWarning) {
                        context.commit('setBlacklistData', { warning: true, blocked: false })
                    }
                    if (response.blackListBlocked) {
                        context.commit('setBlacklistData', { warning: false, blocked: true })
                    }
                    return false
                }
            })
            .then(function () {
                if (context.state.hasLogin) {
                    return true
                } else {
                    return false
                }
            })
    },
    editPassword(context, params) {
        if (params.type == 1) {
            return api
                .login(`admin/employee/${params.id}/setpassword`, { password: params.password, invitation_id: params.sInvitationId })
                .then(function (response) {
                    localStorage.setItem('token', response.token)
                    context.commit('setEmployeeMultiaccount', response.data)
                    if (Object.keys(response.data.accounts).length == 1) {
                        const account = Object.values(response.data.accounts)[0]
                        return context.dispatch('changeAccount', { id: account.account_id, data: account }).then(() => {
                            return response
                        })
                    }
                    return context.dispatch('navigation/setPage', { name: 'Multiaccount' }, { root: true })
                })
        } else {
            return api
                .login(`admin/employee/${params.id}/editpassword`, { password: params.password, invitation_id: params.sInvitationId })
                .then(function (response) {
                    localStorage.setItem('token', response.token)
                    context.commit('setEmployeeMultiaccount', response.data)
                    if (Object.keys(response.data.accounts).length == 1) {
                        const account = Object.values(response.data.accounts)[0]
                        return context.dispatch('changeAccount', { id: account.account_id, data: account }).then(() => {
                            return response
                        })
                    }
                    return context.dispatch('navigation/setPage', { name: 'Multiaccount' }, { root: true })
                })
        }
    },
    checkResetPassword(context, params) {
        return api.get('admin/resetpass/' + params.id).then(function (response) {
            return response.status
        })
    },
    initAccount(context) {
        // this._vm.$overlay.loading()

        if (fnCheckConnection()) {
            return api
                .getSession()
                .then(function (response) {
                    if (response && response.status == 'false') {
                        router.push('/login')
                    }
                    if (response.status) {
                        context.commit('setEmployeeMultiaccount', false)
                        var roles = response.data.roles
                        context.commit('employee/setRoles', roles, { root: true })
                        delete response.data.roles
                        var account = {
                            id: response.data.account_id,
                            avatar: response.data.avatar,
                            account_name: response.data.account_name,
                            timezone: response.data.timezone,
                            config: response.data.config
                        }

                        if(response.data.config.api){
                            localStorage.setItem('api', response.data.config.api)
                        }else{
                            if(localStorage.getItem('api')){
                                localStorage.removeItem('api')
                            }
                        }

                        context.commit('account/setOrganization', { account: account }, { root: true })
                        context.commit('login', response)
                        context.commit('setReleases', response.data)
                        context.commit('setChatActive', response.data.chat, { root: true })
                        context.commit('setExternal', parseInt(response.data.external))
                        context.rootState.language = localStorage.getItem('language')

                        const canCreateUsers =
                            response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' && response.data.config.canCreateUsers
                                ? response.data.config.canCreateUsers
                                : 0
                        context.commit('setCanCreateUsers', canCreateUsers, { root: true })

                        const productNextState =
                            response.data.config && typeof response.data.config.nextState !== 'undefined' && response.data.config.nextState
                                ? response.data.config.nextState
                                : 0
                        context.commit('setProductNextState', productNextState, { root: true })
                    } else {
                        return false
                    }
                })
                .then(function () {
                    if (context.state.hasLogin) {
                        //LOAD CUSTOM FIELDS
                        context.dispatch('loadCustomFields')
                        return true
                    } else {
                        return false
                    }
                })
        } else {
            return false
        }
    },
    doLogout(context, params) {
        if (context.getters.getExternal == 1) {
            return api.post('logout/admin/externaluser', {}).then((response) => {
                return response
            })
        }
        return true
    },
    getInvitationInformation(context, params) {
        return api.get(`admin/invitation/${params.sInvitationId}`).then((response) => {
            return response
        })
    },
    loadReleases(context, params) {
        return api.get('admin/releases').then((response) => {
            context.commit('setAllReleases', response)
            return response
        })
    },
    loginMultiAccount(context, { email, password }) {
        return api
            .login('login/admin/multiaccount', { email, password })
            .then((response) => {
                if (response.status) {
                    localStorage.setItem('token', response.token)
                    context.commit('setEmployeeMultiaccount', response.data)
                    if (Object.keys(response.data.accounts).length == 1) {
                        const account = Object.values(response.data.accounts)[0]
                        return context.dispatch('changeAccount', { id: account.account_id, data: account }).then(() => {
                            return response
                        })
                    }
                    return context.dispatch('navigation/setPage', { name: 'Multiaccount' }, { root: true }).then(() => {
                        return response
                    })
                } else {
                    if (response.redirect) {
                        window.location.href = response.redirect
                        return response
                    }
                    if (response.blackListWarning) {
                        context.commit('setBlacklistData', { warning: true, blocked: false })
                    }
                    if (response.blackListBlocked) {
                        context.commit('setBlacklistData', { warning: false, blocked: true })
                    }
                    return false
                }
            })
            .catch((error) => {
                console.error(error)
                return false
            })
    },
    initMultiAccount(context) {
        return api
            .get('admin/init/multiaccount')
            .then((response) => {
                localStorage.setItem('token', response.token)
                context.commit('setEmployeeMultiaccount', response.data)
                return context.dispatch('navigation/setPage', { name: 'Multiaccount' }, { root: true })
            })
            .catch((error) => {
                console.error(error)
                return false
            })
    },
    initReportAccess(context, params) {
        return api
            .get(`login/admin/report/${params}`)
            .then(function (response) {
                if (response && response.status == 'false') {
                    router.push('/login')
                }

                if (response.status) {
                    //context.commit('setEmployeeMultiaccount', false)
                    var account = {
                        id: response.data.account_id,
                        avatar: response.data.avatar,
                        account_name: response.data.name,
                        timezone: response.data.timezone
                    }
                    context.commit('account/setOrganization', { account: account }, { root: true })
                    context.commit('setReportAccess', response)
                    context.rootState.language = response.data.lang

                    /* const canCreateUsers = response.data.config && typeof response.data.config.canCreateUsers !== 'undefined' && response.data.config.canCreateUsers ? response.data.config.canCreateUsers : 0 */
                    //context.commit('setCanCreateUsers', canCreateUsers, { root: true })
                } else {
                    return false
                }
            })
            .catch((error) => {
                console.error(error)
                return false
            })
    },
    loadCustomFields(context, params) {
        return api.get('account/custom-fields').then((response) => {
            context.commit('setCustomFields', response.data)
            return response
        })
    },
    externalLogin(context, accountId) {
        return api.get('login/admin/externaluser/' + accountId).then((response) => {
            return response
        })
    }
}

// mutations
const mutations = {
    setReleases(state, payload) {
        var releases = state.releases
        releases = payload.releases
        state.releases = releases
        Vue.set(state, 'releases', releases)
    },
    setCustomFields(state, payload) {
        let customFields = state.customFields
        customFields = payload
        state.customFields = customFields
        Vue.set(state, 'customFields', customFields)
    },
    setAllReleases(state, payload) {
        var allReleases = state.allReleases
        allReleases = payload.data
        state.allReleases = allReleases
        Vue.set(state, 'allReleases', allReleases)
    },
    login(state, response) {
        if (response.status) {
            state.hasLogin = true

            var dt = response.data
            var local = { ...state.local }
            var account = { id: dt.account_id, avatar: dt.avatar, account_name: dt.account_name }
            local.user = dt

            state.trial_end_date = dt.trial_end_date
            state.trial_link = dt.trial_link
            state.productAvailability = dt.productAvailability
            state.type = dt.type

            store.commit('loginUser/setIsSuperAdmin', parseInt(dt.superadmin), { root: true })

            if (localStorage) {
                localStorage.setItem('token', dt.token)
                localStorage.setItem('account', JSON.stringify(account))
                localStorage.setItem('config', JSON.stringify(dt.config))
                localStorage.setItem('is_admin', JSON.stringify(dt.is_admin))
                localStorage.setItem('app_icon', dt.app_icon)
                localStorage.setItem('multiple_accounts', JSON.stringify(dt.accounts))
                localStorage.setItem('hasLogin', JSON.stringify(state.hasLogin))

                local.app_icon = dt.app_icon
                state.local = local

                state.config = dt.config
                state.account = account
                state.multipleAccounts = dt.accounts

                let lang = ''

                switch (dt.lang) {
                    case 'es_ES':
                        lang = 'es'
                        break
                    case 'en_GB':
                    case 'en_US':
                        lang = 'en'
                        break
                    case 'pt_PT':
                        lang = 'pt'
                        break
                    case 'de_DE':
                        lang = 'de'
                        break
                    case 'fr_FR':
                        lang = 'fr'
                        break
                    default:
                        lang = 'es'
                        break
                }

                localStorage.setItem('language', lang)
                i18n.loadLanguageAsync(lang)

                // if (dt.is_admin) {
                localStorage.setItem('user', JSON.stringify(dt))
                // } else {
                // localStorage.setItem('location', JSON.stringify({ 'id': dt.id, 'name': dt.name, 'avatar': dt.avatar, 'token': dt.token }))
                // }
                // set LOCAL

                if (dt.reason_postpone) {
                    let rsPostpone = dt.reason_postpone
                    rsPostpone['0'] = {
                        id: '0',
                        name: i18n.t('answer.postpone.default_reason')
                    }
                    store.commit('setReasonPostpone', rsPostpone)
                }
                if (dt.reason_justify) {
                    let rsJustify = dt.reason_justify
                    rsJustify['0'] = {
                        id: '0',
                        name: i18n.t('answer.justify.default_reason')
                    }
                    store.commit('setReasonJustify', rsJustify)
                }
            }
        } else {
            state.hasLogin = false
        }
    },
    setLogin(state) {
        if (typeof localStorage.token !== 'undefined') {
            state.hasLogin = true
        }
    },
    logout(state) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('location')
        localStorage.removeItem('account')
        localStorage.removeItem('config')
        localStorage.removeItem('employee_id')
        localStorage.removeItem('is_admin')
        localStorage.removeItem('app_icon')
        localStorage.removeItem('hasLogin')

        // reset LOCAL

        state.hasLogin = false
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    setBlacklistData(state, data) {
        const blackListData = {
            warning: data.warning,
            blocked: data.blocked
        }
        state.blackListData = blackListData
        Vue.set(state, 'blacklistData', blackListData)
    },
    setEmployeeMultiaccount(state, data) {
        if (data) data.avatar = data.avatar ? css_image_url + 'avatar/' + data.avatar : ''
        state.employeeMultiaccount = data
        if (data) localStorage.setItem('employeeMultiaccount', JSON.stringify(data))
        else localStorage.removeItem('employeeMultiaccount')
        Vue.set(state, 'employeeMultiaccount', data)
    },
    setExternal(state, value) {
        state.external = value
    },
    setReportAccess(state, response) {
        if (response.status) {
            state.reportAccess = true

            var dt = response.data
            var local = { ...state.local }
            var account = { id: dt.account_id, avatar: dt.avatar, account_name: dt.account_name }
            local.user = dt

            if (localStorage) {
                localStorage.setItem('token', dt.token)
                localStorage.setItem('account', JSON.stringify(account))
                localStorage.setItem('reportAccess', JSON.stringify(true))

                local.app_icon = dt.app_icon
                state.local = local

                state.account = account

                let lang = ''

                switch (dt.lang) {
                    case 'es_ES':
                        lang = 'es'
                        break
                    case 'en_GB':
                    case 'en_US':
                        lang = 'en'
                        break
                    case 'pt_PT':
                        lang = 'pt'
                        break
                    case 'de_DE':
                        lang = 'de'
                        break
                    default:
                        lang = 'es'
                        break
                }

                localStorage.setItem('language', lang)
                i18n.loadLanguageAsync(lang)
                localStorage.setItem('user', JSON.stringify(dt))
            }
        } else {
            state.hasLogin = false
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
